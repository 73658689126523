import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  visibleMenu = false;
  visibleBurgerMenu = false;

  currentPageType: string;

  constructor(private authService: AuthService, private router: Router) {}

  async ngOnInit(): Promise<void> {
    this.currentPageType = 'user';
    this.authService.getLoginUser().subscribe((user) => {
      if (user == null) {
        this.visibleMenu = false;
        return;
      }
      this.visibleMenu = true;
      user
        .getIdTokenResult()
        .then((idTokenResult) => {
          this.currentPageType =
            idTokenResult.claims.gadmin === true ? 'gadmin' : 'user';
        })
        .catch((e) => {
          debugger;
          console.log(e);
        });
    });
  }

  toggleBurgerMenu(): void {
    if (this.visibleBurgerMenu) {
      this.visibleBurgerMenu = false;
    } else {
      this.visibleBurgerMenu = true;
    }
  }
}
