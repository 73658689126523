import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  groupId: string;

  constructor(private afAuth: AngularFireAuth) {}

  login(
    email: string,
    password: string
  ): Promise<firebase.auth.UserCredential> {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  logout(): Promise<void> {
    return this.afAuth.signOut();
  }

  getLoginUser(): Observable<firebase.User> {
    return this.afAuth.user;
  }

  async getGroupId(): Promise<string> {
    const user = await this.afAuth.currentUser;
    const token = await user.getIdTokenResult();
    return token.claims.group;
  }
}
