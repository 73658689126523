<nav class="navbar is-primary" role="navigation" aria-label="main navigation">
  <div class="navbar-brand">
    <p class="navbar-item"><b>PLAT-EYE</b></p>
    <a
      role="button"
      class="navbar-burger burger"
      [class.is-active]="visibleBurgerMenu"
      aria-label="menu"
      aria-expanded="false"
      data-target="navbarBasicExample"
      (click)="toggleBurgerMenu()"
    >
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>

  <app-gadmin-header
    id="navbarBasicExample"
    class="navbar-menu"
    *ngIf="visibleMenu && currentPageType === 'gadmin'"
    [class.is-active]="visibleBurgerMenu"
  ></app-gadmin-header>

  <app-user-header
    id="navbarBasicExample"
    class="navbar-menu"
    *ngIf="visibleMenu && currentPageType === 'user'"
    [class.is-active]="visibleBurgerMenu"
  ></app-user-header>
</nav>
