export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyDL_KukJ3lhr63X7hor7jwOVednBHBaOG4',
    authDomain: 'fir-plat-eye.firebaseapp.com',
    databaseURL: 'https://fir-plat-eye.firebaseio.com',
    projectId: 'fir-plat-eye',
    storageBucket: 'fir-plat-eye.appspot.com',
    messagingSenderId: '280091771677',
    appId: '1:280091771677:web:9609b5da7362172c2557f4',
  },
};
