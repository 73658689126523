import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-gadmin-header',
  templateUrl: './gadmin-header.component.html',
  styleUrls: ['./gadmin-header.component.scss'],
})
export class GadminHeaderComponent implements OnInit {
  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit(): void {}

  async logout(): Promise<void> {
    await this.authService.logout();
    this.router.navigate(['/gadmin/login']);
  }
}
