<div class="navbar-start">
  <a class="navbar-item" routerLink="/gadmin/user" routerLinkActive="is-active">
    ユーザ別カメラ参照設定
  </a>
  <a
    class="navbar-item"
    routerLink="/gadmin/camera"
    routerLinkActive="is-active"
  >
    カメラ管理
  </a>
  <a
    class="navbar-item"
    routerLink="/capture-image"
    routerLinkActive="is-active"
  >
    カメラ画像一覧
  </a>
</div>
<div class="navbar-end">
  <div class="navbar-item">
    <div class="buttons">
      <a class="button is-light" (click)="logout()"> ログアウト </a>
    </div>
  </div>
</div>
