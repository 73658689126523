<div class="navbar-start">
  <a
    class="navbar-item"
    routerLink="/capture-image"
    routerLinkActive="is-active"
  >
    カメラ画像一覧
  </a>
</div>
<div class="navbar-end">
  <div class="navbar-item">
    <div class="buttons">
      <a class="button is-light" (click)="logout()"> ログアウト </a>
    </div>
  </div>
</div>
